import { useEffect, useRef, useState, useMemo } from "react";
import { formatNumber } from "../../helpers/price";
import useResizeObserver from "use-resize-observer";

function CaseOpeningMultiple({
  selectedId,
  extendedItems,
  setIsSpinning,
  setWinnedPrizeBlock,
  setWinnedPrize,
  showAnimation,
  isSpinning,
  playWinnedSound
}) {
  const wheelRef = useRef(null);
  const { ref, height = 200 } = useResizeObserver();

  // Используем тот же подход к формированию массива предметов
  const displayItems = useMemo(() => {
    const targetLength = 60;
    let tempItems = [];
    
    // Функция для перемешивания массива
    const shuffleArray = (array) => {
      const newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    };

    // Заполняем массив случайными предметами, каждый раз перемешивая по-новому
    while (tempItems.length < targetLength) {
      const shuffledItems = shuffleArray(extendedItems);
      // Берем только часть перемешанных предметов, чтобы увеличить разнообразие
      tempItems = tempItems.concat(shuffledItems.slice(0, Math.min(10, shuffledItems.length)));
    }
    
    // Обрезаем до нужной длины
    let finalArray = tempItems.slice(0, targetLength);
    
    // Помещаем выигрышный предмет на 50-ю позицию
    const winningItem = extendedItems.find(item => item.item_id === selectedId.item_id);
    if (winningItem) {
      finalArray[50] = winningItem;
    }
    
    return finalArray;
  }, [extendedItems, selectedId]);

  const spinWheel = () => {
    const winningIndex = 50; // Фиксированная позиция выигрышного предмета

    setWinnedPrizeBlock(false);
    setIsSpinning(true);
    setWinnedPrize(selectedId);

    const wrapper = wheelRef.current;
    if (!wrapper) return;

    const viewportCenter = wrapper.offsetHeight / 2;
    const centeringOffset = height / 2;
    const selectedItem = wheelRef.current?.children[winningIndex];
    
    const selectedItemCenterPosition = selectedItem?.offsetTop + height / 2;
    const correction = viewportCenter - selectedItemCenterPosition;

    // Используем ту же логику расчета дистанции
    const spinDistance = calculateSpinDistance(correction, centeringOffset, height);

    if (showAnimation) {
      animateWheel(spinDistance, correction, 10000);
    } else {
      animateWheel(spinDistance, correction, 2000);
    }
  };

  const calculateSpinDistance = (correction, centeringOffset, height) => {
    const min = Math.ceil(correction - centeringOffset);
    const max = Math.floor(correction + centeringOffset);
    
    if (Math.random() > 0.5) {
      return Math.floor(Math.random() * ((min + height/6) - min + 1)) + (min + height/6);
    }
    return Math.floor(Math.random() * (max - (max - height/6) + 1)) + (max - height/6);
  };

  const animateWheel = (spinDistance, correction, duration) => {
    if (!wheelRef.current) return;

    wheelRef.current.style.transition = `transform ${duration/1000}s cubic-bezier(0.15, 1, 0.40, 1)`;
    wheelRef.current.style.transform = `translateY(${spinDistance}px)`;
    
    setTimeout(() => {
      setIsSpinning(false);
      if (wheelRef.current) {
        wheelRef.current.style.transition = "transform 0.5s ease-out";
        wheelRef.current.style.transform = `translateY(${correction}px)`;
      }
      setWinnedPrizeBlock(true);
      playWinnedSound();
    }, duration - 1000);
  };

  const resetWheel = () => {
    if (wheelRef.current) {
      wheelRef.current.style.transition = "none";
      wheelRef.current.style.transform = "translateY(0)";
      void wheelRef.current.offsetHeight;
    }
  };

  useEffect(() => {
    resetWheel();
    spinWheel();
  }, [height]);

  return (
    <div className="case_opening_multiple_items" ref={wheelRef}>
      {displayItems.map((item, index) => (
        <div
          key={index}
          className={
            item.id % 2 === 0
              ? `case_opening_item multiple case_opening_item_bg_firstly ${index}`
              : `case_opening_item multiple case_opening_item_bg_secondary ${index}`
          }
          ref={index === 0 ? ref : null}
        >
          <div className="case_opening_item_img">
            <img src={item.image} alt="" />
          </div>
          <div className="case_opening_item_description">
            <p>{item.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CaseOpeningMultiple;
