const mainApiOptions = {
  baseUrl: 'https://server.primoloot.com',

  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
};

class MainApi {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }
  _checkResponseStatus(response) {
    return response.ok
      ? response.json()
      : response.json().then((err) => Promise.reject(err));
  }

  async getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  async _sendRequest({
    endpoint,
    method = "GET",
    body,
    requiresToken = false,
  }) {
    const headers = { ...this._headers };

    if (requiresToken) {
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }

    let ref = await this.getCookie('ref')

    if(ref){
      headers["Ref-Code"] = ref;
    }

    const res = await fetch(`${this._baseUrl}${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    return this._checkResponseStatus(res);
  }

  // Sign actions list
  async signUpAction(userData) {
    return this._sendRequest({
      endpoint: `/sign_up`,
      method: "POST",
      body: userData,
    });
  }
  async signInAction(userData) {
    return this._sendRequest({
      endpoint: `/sign_in`,
      method: "POST",
      body: userData,
    });
  }
  //async loginAction(userData) {
  //  return this._sendRequest({
  //    endpoint: `/sign_in`,
  //    method: "POST",
  //    body: userData,
  //  });
  //}

  // Socials auth/login
  async authGoogleAction(body) {
    return this._sendRequest({
      endpoint: `/sign_in/google`,
      method: "POST",
      body: body
    });
  }

  async generateTgSession() {
    return this._sendRequest({
        endpoint: '/auth/tg-bot/generate-session',
        method: 'POST',
    });
  }

  async authorizeTgSession(body) {
    return this._sendRequest({
        endpoint: '/auth/tg-bot/authorize-session',
        method: 'POST',
        body: body,
    });
  }

  async authVKAction(body) {
    return this._sendRequest({
      endpoint: `/auth/vk`,
      method: "POST",
      body: body,
    });
  }
  async getTokenVK(userData) {
    return this._sendRequest({
      endpoint: `/auth/vk/token/?access_token=${userData}`,
      method: "POST",
    });
  }
  async authTGAction(body) {
    return this._sendRequest({
      endpoint: "/sign_in/telegram",
      method: "POST",
      body: body
    });
  }
  async authMailruAction(userData) {
    return this._sendRequest({
      endpoint: `/auth/mailru`,
      method: "POST",
      body: userData,
    });
  }
  async authYandexAction(userData) {
    return this._sendRequest({
      endpoint: `/auth/yandex`,
      method: "POST",
      body: userData,
    });
  }

  // User me
  async reEnter() {
    return this._sendRequest({
      endpoint: "/user",
      method: "GET",
      requiresToken: true,
    });
  }

  // update user data
  async updateUserName(userData) {
    return this._sendRequest({
      endpoint: `/user/update`,
      method: "PUT",
      body: userData,
      requiresToken: true,
    });
  }
  async updateUserEmail(userData) {
    return this._sendRequest({
      endpoint: `/user/update`,
      method: "PUT",
      body: userData,
      requiresToken: true,
    });
  }
  async updateUserPassword(userData) {
    return this._sendRequest({
      endpoint: `/user/update`,
      method: "PUT",
      body: userData,
      requiresToken: true,
    });
  }
  // Get all Cases
  async getAllCases(category_id) {
    return this._sendRequest({
      endpoint: `/cases/?category=${category_id}`,
      method: "GET",
      // requiresToken: true,
    });
  }

  // Get case Items
  async getCaseItems(id) {
    return this._sendRequest({
      endpoint: `/cases/${id}/`,
      method: "GET",
      // requiresToken: true,
    });
  }

  // Get case Items
  async caseRandomizer(data) {
    return this._sendRequest({
      endpoint: `/randomaizer/case/opening`,
      method: "POST",
      body: data,
      // requiresToken: true,
    });
  }

  async getAnalyticsFooter() {
    return this._sendRequest({
      endpoint: `/analytics`,
      method: "GET",
      //requiresToken: true,
    });
  }

  async openCase(id) {
    return this._sendRequest({
      endpoint: `/cases/${id}/open_case/`,
      method: "POST",
      requiresToken: true,
    });
  }

  async openCaseCount(id, count) {
    return this._sendRequest({
      endpoint: `/cases/${id}/open_case/${count}`,
      method: "POST",
      requiresToken: true,
    });
  }

  async shopItems() {
    return this._sendRequest({
      endpoint: `/shop/items`,
      method: "GET",
      //requiresToken: true,
    });
  }

  async userItems(limit, offset) {
    return this._sendRequest({
      endpoint: `/user/items?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }

  async deleteUserItemsAction(id) {
    return this._sendRequest({
      endpoint: `/user/item/sale/${id}`,
      method: "DELETE",
      requiresToken: true,
    });
  }

  async deleteUserAllItemsAction(body) {
    return this._sendRequest({
      endpoint: `/user/item/sale_all`,
      method: "POST",
      requiresToken: true,
      body: body,
    });
  }

  async deleteUserItemsAllAction() {
    return this._sendRequest({
      endpoint: `/user/item/sale_all`,
      method: "DELETE",
      requiresToken: true,
    });
  }

  async userItemsHistory(limit, offset) {
    return this._sendRequest({
      endpoint: `/user/items/history?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }

  async getCasesAll() {
    return this._sendRequest({
      endpoint: `/cases/category_and_cases/?limit=10000&offset=0`,
      method: "GET",
      //requiresToken: true,
    });
  }

  async getCasesCategory() {
    return this._sendRequest({
      endpoint: `/cases/cases_category/`,
      method: "GET",
      //requiresToken: true,
    });
  }

  async shopBuy(id) {
    return this._sendRequest({
      endpoint: `/shop/buy/${id}`,
      method: "POST",
      requiresToken: true,
    });
  }

  async outputsPostAction(body) {
    return this._sendRequest({
      endpoint: `/outputs/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async userGenshinUidAction(body) {
    return this._sendRequest({
      endpoint: `/user/genshin_uid/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async upDateUserUID(userData) {
    return this._sendRequest({
      endpoint: `/user/update`,
      method: "PUT",
      body: userData,
      requiresToken: true,
    });
  }

  async UserRefAction() {
    return this._sendRequest({
      endpoint: `/user/ref`,
      method: "GET",
      requiresToken: true,
    });
  }

  async upgradeItems(limit, offset, sort, search, price, item) {
    let isSearch = '';
    if (search) {
      isSearch = `&search=${search}`
    }
    console.log(price)
    return this._sendRequest({
      endpoint: `/upgrade/items/?limit=${limit}&offset=${offset}&ordering=${sort}${isSearch}`,
      method: "POST",
      requiresToken: true,
      body: {
        ...(price ? { balance: price } : undefined),
        ...(item ? { upgrade_items: [ item.id ] } : undefined)
      }
    });
  }

  async upgradeUserItems(limit, offset) {
    return this._sendRequest({
      endpoint: `/upgrade/user_items/?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }

  async upgradeGetMinimalValues(body) {
    return this._sendRequest({
      endpoint: `/upgrade/get_minimal_values/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async upgradeUpgradeData(body) {
    return this._sendRequest({
      endpoint: `/upgrade/get_upgrade_data/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async upgradeUpgradeItems(body) {
    return this._sendRequest({
      endpoint: `/upgrade/upgrade_items/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async upgradeUserItemsUpgrdeHistory(limit, offset) {
    return this._sendRequest({
      endpoint: `/user/items/upgrde_history?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }

  async contests(limit, offset) {
    return this._sendRequest({
      endpoint: `/contests/?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }

  async contestsPOST(id) {
    return this._sendRequest({
      endpoint: `/contests/${id}/`,
      method: "POST",
      requiresToken: true,
    });
  }

  async getUsersId(id) {
    return this._sendRequest({
      endpoint: `/user/${id}/`,
      method: "GET",
      requiresToken: false,
    });
  }

  async getUsersIdItems(id, limit, offset) {
    return this._sendRequest({
      endpoint: `/user/${id}/items?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: false,
    });
  }
  async getUsersIdUpgrades(id, limit, offset) {
    return this._sendRequest({
      endpoint: `/user/${id}/upgrade_history?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: false,
    });
  }


  async userRefPOSTAction(body) {
    return this._sendRequest({
      endpoint: `/user/ref`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }

  async refOutput(limit, offset) {
    return this._sendRequest({
      endpoint: `/ref_output/?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }
  async refOutputIdAction(id) {
    return this._sendRequest({
      endpoint: `/ref_output/${id}/`,
      method: "GET",
      requiresToken: true,
    });
  }
  async refOutputPOST(body) {
    return this._sendRequest({
      endpoint: `/ref_output/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }
  async payments(limit, offset) {
    return this._sendRequest({
      endpoint: `/payments/?limit=${limit}&offset=${offset}`,
      method: "GET",
      requiresToken: true,
    });
  }
  async paymentsPOST(body) {
    return this._sendRequest({
      endpoint: `/payments/`,
      method: "POST",
      body: body,
      requiresToken: true,
    });
  }
  async promoPOST(body) {
    return this._sendRequest({
      endpoint: `/promo/activate/`,
      method: "POST",
      requiresToken: true,
      body: body
    });
  }
}

export const mainApi = new MainApi(mainApiOptions);
