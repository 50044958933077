import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import userReducer from "./user-reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import snackbarReducer, { resetSnackbarState } from "./snackbar-reducer";
import modalWithdrawReducer from "./modal-withdraw-reducer";
import modalBonusCodeReducer from "./modal-bonuscode-reducer";

const rootReducer = combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
  modalWithdraw: modalWithdrawReducer,
  modalBonusCode: modalBonusCodeReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["snackbar"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

store.dispatch(resetSnackbarState());

export const persistor = persistStore(store);
