import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Preloader from "../../components/preloader/preloader";
import Loader from "../../components/loader/loader"
import CaseItem from "../../components/case-item/case-item";

import CaseOpening from "../../components/case-opening/case-opening";
import { mainApi } from "../../components/utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet-icon.svg";
import openfast_icon from "../../assets/icons/opencase-fast-icon.png";
import "./case-page.css";
import { loginUserAction } from "../../redux/user-reducer";


function CasePage({ setLoginModal }) {
  const isLogged = useSelector((state) => state.user.user.is_logged);
  const isBalancEnough = useSelector((state) => state.user.user.balance);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [caseElement, setCaseElement] = useState();
  const [caseItems, setCaseItems] = useState([]);
  const [selectedId, setselectedId] = useState();
  const [count, setCount] = useState(1);
  const [extendedItems, setExtendedItems] = useState([]);
  const [spinningProcess, setSpinningProcess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [isLoading, setIsLoading] = useState(true); 

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const openCaseCount = (animation = true) => {
    setIsDisabled(true);
    mainApi
      .openCaseCount(params.name, count)
      .then((data) => {
        setShowAnimation(animation);
        setselectedId(data);
        setExtendedItems(extendItems(caseItems, data[0].item_id));
        setSpinningProcess(true);
        mainApi

          .reEnter()
          .then((res) => {
            dispatch(loginUserAction(res));
          })
        setIsDisabled(false);
      })
      .catch((error) => {
        snackbarActions("error", error)
        console.log("error: ", error);
        setIsDisabled(false);
      });
  };

  

  function extendItems(items, selectedId = null) {
    let tempExtendedItems = [];
    const targetLength = 60; // Main items length
    const extraItems = 10; // Number of extra items to add for infinite scroll effect
    
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // Fill main items
    while (tempExtendedItems.length < targetLength) {
      const shuffledItems = shuffleArray([...items]);
      tempExtendedItems = tempExtendedItems.concat(shuffledItems);
    }

    // Cut to target length
    let finalArray = tempExtendedItems.slice(0, targetLength);

    // Add winning item
    if (selectedId) {
      const selectedItem = items.find(item => item.item_id === selectedId);
      if (selectedItem) {
        finalArray[50] = selectedItem;
      }
    }

    // Add extra items at the end for infinite scroll effect
    const extraItemsArray = shuffleArray([...finalArray]).slice(0, 10);
   
    finalArray = [...finalArray, ...extraItemsArray];


    return finalArray;
  }

  useEffect(() => {
    mainApi
      .getCaseItems(params.name)
      .then((userData) => {
        setCaseElement(userData);
        setCaseItems(userData.items);
        if (userData.items.length > 1) {
          setExtendedItems(extendItems(userData.items));
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      })
              .catch((error) => {
          console.log("error: ", error);
        })
        .finally(() => {
          setIsLoading(false); 
        });
  }, [params.name]);

  React.useEffect(() => {
    // Устанавливаем заголовок страницы с учетом данных о кейсе
    document.title = `${caseElement ? caseElement.name : "Загружается..."} - PrimoLoot`;
  }, [caseElement]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if(isLoading) {
    return <Loader />
  }


  return (

    <div className="page_template case_page">
      <div className="case_title">
        <div className="back_button grey_icon">
          <button onClick={() => navigate(-1)}>
            <BackIcon />
            <p className="p_back">Назад</p>
          </button>
        </div>
        <h1>КЕЙС {caseElement?.name}</h1>
      </div>
      {spinningProcess ? (
        <CaseOpening
          setSpinningProcess={setSpinningProcess}
          caseItems={caseItems}
          selectedId={selectedId}
          extendedItems={extendedItems}
          showAnimation={showAnimation}
          count={count}
        />
      ) : (
          <div className="about_item_bg_wrapper">
            <div className="about_item_bg">
              <div className="about_item_content">
                {caseElement && (
                  <img className="case_page_case_img" src={caseElement.image} alt="" />
                )}
</div>
</div>
                <>
                  {!isLogged ? (
                    <div className="unauthorized_message_wrapper">
                      <div className="unauthorized_message">
                        <p>Вы не авторизованы!</p>
                        <span>
                          Для открытия кейсов необходимо пройти авторизацию
                        </span>
                      </div>
                      <button
                        className="nav_auth_btns not_logined_btn"
                        onClick={() => setLoginModal(true)}
                      >
                        Войти
                      </button>
                    </div>
                  ) : (
                   '' 
                  )}
                   {isLogged && caseElement?.price > isBalancEnough ? (
  <>
    <div className="unauthorized_message">
      <p>
        <span className="case_page_cost">{caseElement.price}₽</span>{" "}
        - НЕ ХВАТАЕТ {caseElement.price - isBalancEnough}₽
      </p>
      <span>Недостаточно средств для открытия кейса</span>
    </div>
    <NavLink to="/deposit">
      <button className="nav_auth_btns topup_page_btn">
        ПОПОЛНИТЬ БАЛАНС <Wallet />
      </button>
    </NavLink>
  </>
) : (
  ""
)} 
{isLogged && caseElement?.price <= isBalancEnough ? (
  <>
    <div className="open_case_block">
                    <div className="upgade_chances">
                    {!caseElement.case_free && (
          <>
                      <div
                        className={count === 1 ? 'upgade_chances_items grey_btn_active': 'upgade_chances_items grey_btn'}
                        onClick={() => setCount(1)}
                      >
                        <p>X1</p>
                      </div>
                      <div
                        className={count === 2 ? 'upgade_chances_items grey_btn_active': 'upgade_chances_items grey_btn'}
                        onClick={() => setCount(2)}
                      >
                        <p>X2</p>
                      </div>
                      <div
                        className={count === 3 ? 'upgade_chances_items grey_btn_active': 'upgade_chances_items grey_btn'}
                        onClick={() => setCount(3)}
                      >
                        <p>X3</p>
                      </div>
                      <div
                        className={count === 4 ? 'upgade_chances_items grey_btn_active': 'upgade_chances_items grey_btn'}
                        onClick={() => setCount(4)}
                      >
                        <p>X4</p>
                      </div>
                      <div
                        className={count === 5 ? 'upgade_chances_items grey_btn_active': 'upgade_chances_items grey_btn'}
                        onClick={() => setCount(5)}
                      >
                        <p>X5</p>
                      </div>
                      </>
        )}
                      </div>
                      {caseElement && (
                        <button
                          className="nav_auth_btns open_case_btn main_btn_green"
                          onClick={() => openCaseCount()}
                          disabled={isDisabled}
                        >
                            ОТКРЫТЬ ЗА {caseElement.case_free ? '0' : `${caseElement.price * count}`} ₽
                        </button>
                      )}
                    <button className="open_fast_btn grey_icon" onClick={() => openCaseCount(false)} disabled={isDisabled}>
                      ОТКРЫТЬ БЫСТРО <img src={openfast_icon} alt="" />{" "}
                    </button>
                  </div>
  </>
) : (
  ''
)} 
                </>
                
              
          

        </div>
      )}
   
      <div className="case_items_block">
        <h3>СОДЕРЖИМОЕ КЕЙСА</h3>
        <div className="case_items_content">
          {Array.isArray(caseItems) && caseItems.length >= 1
            ? caseItems.map((item) => (
                <CaseItem key={item.item_id} item={item} />
              ))
            : ""}
        </div>
      </div>
      
    </div>
  );
}

export default CasePage;
