import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/footer";
import LiveLength from "./components/live-length/live-length";
import LoginAuth from "./components/login-auth/login-auth";
import MainNavbar from "./components/main-navbar/main-navbar";
import TopNavbar from "./components/top-navbar/top-navbar";
import { mainApi } from "./components/utils/main-api";
import CasePage from "./pages/case-page/case-page";
import MainPage from "./pages/main-page/main-page";
import ProfilePage from "./pages/profile-page/profile-page";
import ShopPage from "./pages/shop-page/shop-page";
import Topup from "./pages/topup/topup";
import { loginUserAction } from "./redux/user-reducer";
import SuccesAuthModal from "./components/sucess-auth-modal/success-auth-modal";
import Live from "./pages/live/live";
import Faq from "./pages/faq/faq";
import Agreement from "./pages/agreement/agreement";
import Privacy from "./pages/privacy/privacy";
import Upgrade from "./pages/upgrade/upgrade";
import Giveaway from "./pages/giveaway/giveaway";
import UserPage from "./pages/user-page/user-page";
import Snacbar from "./components/snackbar/snackbar";
import ModalWithdraw from "./components/modal-withdraw/modal-withdraw";
import ModalBonusCode from "./components/modal-bonuscode/modal-bonuscode";

import RefLink from "./components/RefLink/RefLink";
import AdminLog from "./pages/adminauthorization/adminLog";

function App() {

  const isLogged = useSelector((state) => state.user.user.is_logged);

  const [loginModal, setLoginModal] = useState(false);
  const [shopModal, setShopModal] = useState(false);
  const [successLoginModal, setSuccessLoginModal] = useState(false);
  const usersData = useSelector((state) => state.user.user);

  // const { snackbar, modalWithdraw, modalBonusCode} = useSelector((state) => state);
  const snackbar = useSelector((state) => state.snackbar);
  const modalWithdraw = useSelector((state) => state.modalWithdraw);
  const modalBonusCode = useSelector((state) => state.modalBonusCode);

  const dispatch = useDispatch();

  const closeModals = () => {
    setLoginModal(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      mainApi
        .reEnter()
        .then((res) => {
          dispatch(loginUserAction(res));
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const hash = window.location.hash.substring(1).split("=")[1];
    if (code || hash) {
      setLoginModal(true);
    }
  }, []);

  useEffect(() => {
    if (usersData.password) {
      setSuccessLoginModal(true);
    }
  }, [usersData]);

  return (
    <>
    <RefLink />
        <>
          {loginModal || successLoginModal ? (
            <div className="modal_overlay" onClick={() => closeModals()}></div>
          ) : (
            ""
          )}
          {shopModal && (<div className="modal_overlay" onClick={() => setShopModal(false)} />)}
          {snackbar.show ? (
            <Snacbar text={snackbar.text} snackbar={snackbar}/>
          ) : (
            ""
          )}
          {modalWithdraw.show && (
            <ModalWithdraw />
          )}
          {modalBonusCode.show && (
            <ModalBonusCode />
          )}
          <div className="app_content">
            <div className="app_content-wrapper">
            <TopNavbar setLoginModal={setLoginModal} />
            <LiveLength />

            <MainNavbar setLoginModal={setLoginModal} />

            <Suspense fallback={"loading....."}>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/home" element={<MainPage />} />
                <Route path="/deposit" element={<Topup />} />
                <Route path="/profile/*" element={<ProfilePage setLoginModal={setLoginModal}/>} />
                <Route
                  path="/case/:name"
                  element={<CasePage setLoginModal={setLoginModal} setShopModal={setShopModal} />}
                />
                {/* <Route path="/shop" element={<ShopPage setShopModal={setShopModal} shopModal={shopModal} />} /> */}
                <Route path="/cases" element={<MainPage />} />
                <Route path="/adminauthorization" element={<AdminLog setLoginModal={setLoginModal}/>} />
                {/* <Route path="/live" element={<Live />} /> */}
                <Route path="/faq" element={<Faq />} />
                <Route path="/agreement" element={<Agreement />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/upgrade" element={ <Upgrade setLoginModal={setLoginModal} />} />
                <Route path="/giveaway" element={<Giveaway />} />
                <Route path="/user/:id" element={<UserPage />} />

                {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
            </Suspense>
            </div>
            <Footer />
          </div>
          <div>
      </div>
          {loginModal ? <LoginAuth setLoginModal={setLoginModal} /> : ""}
          {successLoginModal ? (
            <SuccesAuthModal setSuccessLoginModal={setSuccessLoginModal} />
          ) : (
            ""
          )}
        </>
    </>
  );
}

export default App;
