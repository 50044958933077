const SHOW = "SHOW";
const CLEAR = "CLEAR";
const RESET_STATE = "RESET_STATE";

const initialState = {
  show: false,
  text: '',
  error: {}
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        show: true,
        text: action.payload.snackText,
        error: action.payload.error
      };
    case CLEAR:
      return {
        ...state,
        show: false,
        text: '',
        error: {}
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export const snackbarAction = (payload) => {
  return {
    type: SHOW,
    payload,
  };
};

export const snackbarClearAction = () => {
  return {
    type: CLEAR
  };
};

export const resetSnackbarState = () => ({
  type: RESET_STATE,
});

export default snackbarReducer;
